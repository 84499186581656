import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';

import {
  Grid,
  Card,
  List,
  useMediaQuery,
  Typography,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';

import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import AttachmentsIcon from '@material-ui/icons/AttachFile';

import RMSMultiSelectAttachments from 'components/RMSMultiSelectAttachments';
import DownloadAttachments from 'components/DownloadAttachments';

import { handleError } from 'reducers/ErrorReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';
import {
  findAttachments,
  uploadAttachment,
  uploadRMSAttachment,
} from 'reducers/helpers/fileHelpers';
import PlusCircleButton from 'components/RMSButtons/RMSAddButton/plusCircleButton';
import { alpha } from '@mui/material';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const PRIMARY_COLOR = '#1977D4';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
  },
  formFields: {
    width: '100%',
    height: '100%',
  },
  formCard: {
    width: '100%',
    height: '100%',
  },
  lists: {
    marginTop: '20px',
  },
  titlecolor: {
    color: theme.palette.colorFont,
  },
  accordion: {
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  summery: {
    borderRadius: '10px !important',
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  list_root: {
    width: '100%',
    paddingTop: '1px',
    paddingLeft: '5px',
    overflowY: 'scroll',
    // '&::-webkit-scrollbar': {
    //   width: 10,
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#888',
    //   visibility: 'visible',
    // },
  },
  listHeader: {
    background: alpha('#1976d2', 0.2),
    color: theme.palette.colorFont,
    borderRadius: '5px',
    paddingTop: '2px',
    paddingRight: '8px',
    height: '30px',
    alignItems: 'center',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    marginLeft: '28px',
    width: '100%',
  },
  rootSortIcon: {
    '&:hover': {
      color: 'white !important',
    },
  },
  activeSortIcon: {
    color: 'white !important',
  },
  sortIcon: {
    color: 'white !important',
    '&:hover': {
      color: 'white !important',
    },
  },
}));

const Attachment = (props) => {
  const {
    pane,
    setPane,
    paneName,
    sectionTitle,
    incident,
    wsClient,
    expandList,
    editAttachment,
    attachmentType = 'INCIDENT',
    warrantId = null,
  } = props;
  const classes = useStyles();

  const [openAttachment, setOpenAttachment] = useState(false);
  const [incidentState, setIncidentState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
    changes: false,
  });
  const [cardExpanded, setCardExpanded] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);
  const [warrantAttachments, setWarrantAttachments] = useState([]);
  const [incidentAttachments, setIncidentAttachments] = useState([]);
  const [sortedAttachments, setSortedAttachments] = useState([]);

  const [sortState, setSortState] = useState({
    Entered: { active: true, direction: 'desc' },
    Edited: { active: false, direction: 'desc' },
  });
  const [sortCompleted, setSortCompleted] = useState(0);

  const isMediumScreenOrSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const userName = useSelector((state) => state.user.userData?.user.Username);
  const userAgency = useSelector((state) => state.user.userAgency);
  let permission = getFullPermissions('rms', 'Incident Attachment', userAgency);

  useEffect(() => {
    let cardHeight = 0;
    if (incidentAttachments?.length || warrantAttachments?.length) {
      if (cardExpanded) {
        cardHeight =
          attachmentType === 'Warrant'
            ? 34 * warrantAttachments?.length + 4
            : incidentAttachments.length * 34 + 4;
        cardHeight += 'px';
      } else {
        cardHeight =
          attachmentType === 'Warrant'
            ? 34 * Math.min(warrantAttachments?.length, 2) + 4
            : 34 * Math.min(incidentAttachments?.length, 2) + 4;
        cardHeight += 'px';
      }
    }
    setCardHeight(cardHeight);
  }, [incidentAttachments, cardExpanded]);

  useEffect(() => {
    setCardExpanded(expandList);
  }, [expandList]);

  useEffect(() => {
    if (incident.ptsIncidentId && editAttachment === null) {
      getAttachments();
    }
    if (attachmentType === 'Warrant') {
      getWarrantAttachments();
    }
  }, [incident.ptsIncidentId, editAttachment]);

  useEffect(() => {
    if (incidentAttachments && incidentAttachments.length > 0 && attachmentType === 'INCIDENT') {
      if (sortState['Entered'].active) {
        incidentAttachments.sort((a, b) => {
          const dateA = new Date(a.created);
          const dateB = new Date(b.created);
          // Ascending order
          if (sortState['Entered'].direction === 'asc') {
            return dateA - dateB;
          }
          // Descending order
          return dateB - dateA;
        });
      } else if (sortState['Edited'].active) {
        incidentAttachments.sort((a, b) => {
          const dateA = new Date(a.updated);
          const dateB = new Date(b.updated);
          // Ascending order
          if (sortState['Edited'].direction === 'asc') {
            return dateA - dateB;
          }
          // Descending order
          return dateB - dateA;
        });
      }

      setSortCompleted(sortCompleted + 1);

      setSortedAttachments(incidentAttachments);
    }
  }, [
    sortState['Entered'].direction,
    sortState['Entered'].active,
    sortState['Edited'].direction,
    sortState['Edited'].active,
    attachmentType,
    incidentAttachments.length,
  ]);

  const getWarrantAttachments = async () => {
    const result = await findAttachments('Warrant', warrantId);
    let atts = [];
    result?.map((r) => {
      atts.push({
        ptsAttachmentId: r.ptsAttachmentID,
        name: r.FileName,
        type: r.FileType,
        dataURL: r.FileObject,
        description: r.Description,
        updatedBy: r.UpdatedBy,
        updated: r.Updated,
        created: r.Created,
        createdBy: r.CreatedBy,
        ParentType: r.ParentType,
        ParentID: r.ptsParentID,
        IsDeleted: r.IsDeleted,
      });
    });
    setWarrantAttachments(atts);
  };

  const getAttachments = async () => {
    const service = wsClient.websocket.service('rms-attachments');
    service.timeout = 200000;

    const result = await service.find({
      query: {
        id: incident.ptsIncidentId,
        type: 'INCIDENT',
      },
    });

    let atts = [];
    result?.map((r) => {
      atts.push({
        ptsAttachmentId: r.ptsAttachmentID,
        name: r.FileName,
        type: r.FileType,
        dataURL: r.FileObject,
        description: r.Description,
        updatedBy: r.UpdatedBy,
        updated: r.Updated,
        created: r.Created,
        createdBy: r.CreatedBy,
        ParentType: r.ParentType,
        ParentID: r.ptsParentID,
        IsDeleted: r.IsDeleted,
        codeAttachmentType: r.codeAttachmentType,
      });
    });

    setIncidentState({
      ...incident,
      values: {
        ...incident.values,
        attachments: atts,
      },
    });

    setIncidentAttachments(atts);
  };

  const saveAttachment = async (data) => {
    try {
      const { id, file, type, description, codeAttachmentType } = data;

      if (type === 'Warrant') {
        await uploadAttachment(id, file, type, description, codeAttachmentType);
        getWarrantAttachments();
      } else {
        await uploadRMSAttachment(id, file, type, description, userName, codeAttachmentType);
        getAttachments();
      }
    } catch (err) {
      props.handleError(err);
    }
  };

  const toggleSortOrder = (property) => {
    setSortState((prevState) => {
      const newSortState = { ...prevState };
      const currentSortState = newSortState[property];

      if (currentSortState.active) {
        currentSortState.direction = currentSortState.direction === 'asc' ? 'desc' : 'asc';
      } else {
        for (const key in newSortState) {
          newSortState[key].active = false;
        }
        currentSortState.active = true;
        currentSortState.direction = 'desc';
      }

      return newSortState;
    });
  };

  const generateHeaderRow = () => {
    if (!incidentAttachments?.length || !permission.Read || attachmentType === 'Warrant')
      return null;

    return (
      <Grid container className={classes.listHeader}>
        <Grid container item xs={10} spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            Attachment Number
          </Grid>
          <Grid item xs={2}>
            Type
          </Grid>
          <Grid item xs={2}>
            Description
          </Grid>

          <Grid item xs={2}>
            <TableSortLabel
              classes={{
                root: classes.rootSortIcon,
                active: sortState.Entered.active ? classes.activeSortIcon : '',
                icon: classes.sortIcon,
              }}
              active={sortState.Entered.active}
              direction={sortState.Entered.direction}
              onClick={() => toggleSortOrder('Entered')}>
              Entered
            </TableSortLabel>
          </Grid>

          <Grid item xs={2}>
            <TableSortLabel
              classes={{
                root: classes.rootSortIcon,
                active: sortState.Edited.active ? classes.activeSortIcon : '',
                icon: classes.sortIcon,
              }}
              active={sortState.Edited.active}
              direction={sortState.Edited.direction}
              onClick={() => toggleSortOrder('Edited')}>
              Edited
            </TableSortLabel>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          File Type
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Card
        expanded={pane || cardExpanded}
        // onChange={() => setPane(paneName, !pane)}
        className={classes.accordion}>
        <Grid container spacing={1}>
          <Grid item container spacing={0} style={{ marginLeft: '10px', marginBottom: '3px' }}>
            <Grid item xs={12} style={{ marginTop: '6px', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '5px' }}>
                <AttachmentsIcon style={{ color: PRIMARY_COLOR }} />
              </span>
              <Typography variant="span" className={classes.spanHeader}>
                {sectionTitle} (
                {attachmentType === 'Warrant'
                  ? warrantAttachments?.length
                  : incidentAttachments?.length || 0}
                )
              </Typography>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: '20px',
                  paddingBottom: '3px',
                  paddingTop: '3px',
                }}>
                {attachmentType === 'INCIDENT' && (
                  <span
                    style={{
                      padding: '0px',
                      '&:hover': {
                        background: 'transparent',
                      },
                      border: 'none',
                      background: 'transparent',
                      marginLeft: '5px',
                    }}>
                    <DownloadAttachments />
                  </span>
                )}
                <span
                  style={{
                    padding: '0px',
                    '&:hover': {
                      background: 'transparent',
                    },
                    border: 'none',
                    background: 'transparent',
                    marginLeft: '',
                  }}>
                  <PlusCircleButton
                    tooltipText="Add Attachment"
                    disabled={!permission.Create}
                    onClick={() => setOpenAttachment(true)}
                  />
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDropDownCircleIcon
                    onClick={() => setCardExpanded(!cardExpanded)}
                    className={cardExpanded ? classes.svg_icons_rotated : classes.svg_icons}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </span>
            </Grid>
            <Grid
              container
              xs={12}
              // hidden={!incidentAttachments?.length && !warrantAttachments?.length}
              disablePadding
              className={classes.list_div}>
              {generateHeaderRow()}
              <List className={classes.list_root} style={{ height: cardHeight }} disablePadding>
                <RMSMultiSelectAttachments
                  isAccordion={true}
                  openAttachment={openAttachment}
                  setOpenAttachment={setOpenAttachment}
                  type={attachmentType}
                  attachments={
                    attachmentType === 'Warrant' ? warrantAttachments : sortedAttachments
                  }
                  componentShouldRender={sortCompleted}
                  setWarrantAttachments={setWarrantAttachments}
                  stateHolder={incidentState}
                  setStateHolder={setIncidentState}
                  setField={handleFormChangeNew}
                  save={saveAttachment}
                  warrantId={warrantId}
                  attatchmentType="incident"
                  applyMultipleDownload={attachmentType === 'Warrant' ? false : true}
                />
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
const mapStateToProps = (store) => ({
  incident: store.incident.incident,
  wsClient: store.websocket,
  editAttachment: store.dialogs.editAttachment,
});
export default connect(mapStateToProps, {
  handleError,
})(Attachment);
