import store from '../config/configureStore';
import { excludeIncidentFromIBRSList } from 'utils/functions';
import {
  formatSaveDateFrom,
  formatSaveDateTo,
  formatSaveDate,
  getCurrentDate,
} from './TimeReducer';

export const SET_IBR_EXPAND_OR_NOT_VALUE = 'SET_IBR_EXPAND_OR_NOT_VALUE';
export const SET_BEGINNING_FROM_DATE = 'SET_BEGINNING_FROM_DATE';
export const SET_ENDING_TO_DATE = 'SET_ENDING_TO_DATE';
export const SET_BEGINNING_TO_ENDING_TIME_PERIOD = 'SET_BEGINNING_TO_ENDING_TIME_PERIOD';
export const SET_IBR_STATUS_VALUE = 'SET_IBR_STATUS_VALUE';
export const SET_REPORT_STATUS_VALUE = 'SET_REPORT_STATUS_VALUE';
export const SET_APPROVAL_STATUS_VALUE = 'SET_APPROVAL_STATUS_VALUE';
export const SET_IBR_QUICK_FILTER_VALUE = 'SET_IBR_QUICK_FILTER_VALUE';
export const SET_IBR_GROUP_BY_VALUE = 'SET_IBR_GROUP_BY_VALUE';
export const SET_IBR_SORT_BY_VALUE = 'SET_IBR_SORT_BY_VALUE';
export const SET_IBRS_LIST_CURRENT_VIEW = 'SET_IBRS_LIST_CURRENT_VIEW';
export const SET_IBR_SORT_BY_DIRECTION = 'SET_IBR_SORT_BY_DIRECTION';
export const SET_TOTAL_IBR_ACTIVE_INCIDENTS = 'SET_TOTAL_IBR_ACTIVE_INCIDENTS';
export const SET_IBR_ACTIVE_INCIDENTS = 'SET_IBR_ACTIVE_INCIDENTS';
export const SET_IBR_FILTER_FORM_VALUES = 'SET_IBR_FILTER_FORM_VALUES';
export const SET_IS_VIEW_ERROR = 'SET_IS_VIEW_ERROR';
export const SET_IS_VIEW_NO_ERROR = 'SET_IS_VIEW_NO_ERROR';
export const CLEAR_ALL_IBRS_FILTERS = 'CLEAR_ALL_IBRS_FILTERS';

export const changeIBRSSubmittedState = async (incidentID, status) => {
  const client = store.store.getState().websocket.websocket;
  const service = client.service('rms-create-report');
  service.timeout = 900000;

  await service.patch(incidentID, {
    ibrIsSubmitted: status,
    ibrLastChange: formatSaveDate(getCurrentDate()),
  });
};

export const getAllActiveIncidents = (user, page, rowsPerPage) => {
  const client = store.store.getState().websocket.websocket;
  const incidentFilter = store.store.getState().ibrs.ibrsFilter;
  const agencyID = store.store.getState().user.userAgency;
  const codeIBRStatus = store.store.getState().dictionary.codeIBRStatus;
  const userCurrentClearanceLevel = store.store.getState().user.userCurrentClearanceLevel;

  return async (dispatch) => {
    try {
      const service =
        incidentFilter.ibrsListCurrentView === 'ibrsCardView'
          ? client.service('rms-ibrs-incident')
          : client.service('rms-ibrs-submitted-incidents');
      service.timeout = 50000;

      service
        .find({
          query: {
            page,
            rowsPerPage: rowsPerPage,
            clearanceLevel: parseInt(userCurrentClearanceLevel.Level) || 0,

            beginningFrom: incidentFilter?.beginningFrom
              ? formatSaveDateFrom(incidentFilter?.beginningFrom)
              : null,
            endingTo: incidentFilter?.endingTo ? formatSaveDateTo(incidentFilter?.endingTo) : null,
            quickFilter: incidentFilter?.quickFilter || null,
            reportStatus: incidentFilter?.reportStatus || null,
            ibrStatus: incidentFilter?.ibrStatus || null,
            sortBy: incidentFilter?.sortBy,
            isViewError: incidentFilter?.isViewError ? 1 : 0,
            isViewNoError: incidentFilter?.isViewNoError ? 1 : 0,
            sortByDirection: incidentFilter?.sortByDirection,
            partyName: incidentFilter?.filterForm?.partyName?.FullName || null,
            partyType: incidentFilter?.filterForm?.partyType || null,
            ptsCityID: incidentFilter?.filterForm?.ptsCityID || null,
            ptsAddressID: incidentFilter?.filterForm?.ptsAddressID || null,
            streetName: incidentFilter?.filterForm?.streetName || null,
            streetType: incidentFilter?.filterForm?.streetType || null,
            predirectional: incidentFilter?.filterForm?.predirectional || null,
            postdirectional: incidentFilter?.filterForm?.postdirectional || null,
            unitType: incidentFilter?.filterForm?.unitType || null,
            unitNumber: incidentFilter?.filterForm?.unitNumber || null,
            lastName: incidentFilter?.filterForm?.lastName || null,
            firstName: incidentFilter?.filterForm?.firstName || null,
            middleName: incidentFilter?.filterForm?.middleName || null,
            zone: incidentFilter?.filterForm?.zone || null,
            cadEventNumber: incidentFilter?.filterForm?.cadEventNumber || null,
            status: incidentFilter?.filterForm?.status || null,
            tagType: incidentFilter?.filterForm?.tagType || null,
            tagValue: incidentFilter?.filterForm?.tagValue || null,
            offense: incidentFilter?.filterForm?.offense || null,
            disposition: incidentFilter?.filterForm?.disposition || null,
            lowBlock: incidentFilter?.filterForm?.lowBlock || null,
            highBlock: incidentFilter?.filterForm?.highBlock || null,
            contactMethod: incidentFilter?.filterForm?.contactMethod || null,
            contactInfo: incidentFilter?.filterForm?.contactInfo || null,
            agencyId: agencyID,
          },
        })
        .then((result) => {
          let activeList = [];

          dispatch({
            type: SET_TOTAL_IBR_ACTIVE_INCIDENTS,
            payload: result?.length ? result[0].Count : 0,
          });

          result.map((r) => {
            /* Check for excluding incident for 0 FBICode & exclude offenses */
            let incidentOffenses = r.Offenses ? JSON.parse(r.Offenses) : [];
            if (
              incidentOffenses &&
              incidentOffenses.length > 0 &&
              excludeIncidentFromIBRSList(incidentOffenses) === true
            )
              return;

            activeList.push({
              agencyID: r.AgencyID,
              callType: r.CallType,
              completed: r.Completed,
              cancelled: r.Cancelled,
              dispatched: r.Dispatched,
              createdBy: r.CreatedBy,
              occurred: r.Occurred,
              updated: r.Updated,
              updatedBy: r.UpdatedBy,
              received: r.Received,
              enroute: r.Enroute,
              primaryAddress: r.FullAddress,
              eventType: r.CallType,
              incidentId: r.ptsIncidentID,
              CustomId: r.IncidentID,
              ptsIncidentId: r.ptsIncidentID,
              IsJuvenileReport: r.IsJuvenileReport,
              parties: r.Parties ? JSON.parse(r.Parties) : [],
              properties: r.Properties ? JSON.parse(r.Properties) : [],
              offenses: r.Offenses ? JSON.parse(r.Offenses) : [],
              statuses: r.Statuses ? JSON.parse(r.Statuses) : [],
              synopsis: r.Synopsis ? r.Synopsis : '',
              tags: r.Tags ? JSON.parse(r.Tags) : [],
              nibrsErrors: r.IBRS ? JSON.parse(r.IBRS) : [],
            });
          });

          dispatch({ type: SET_IBR_ACTIVE_INCIDENTS, payload: activeList });

          dispatch(setIbrsIncidents(activeList));
        });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
      return [];
    }
  };
};
export const getAllActiveIncidentsForSelectALL = async (user, page, rowsPerPage) => {
  const client = store.store.getState().websocket.websocket;
  const incidentFilter = store.store.getState().ibrs.ibrsFilter;
  const agencyID = store.store.getState().user.userAgency;
  const codeIBRStatus = store.store.getState().dictionary.codeIBRStatus;
  const userCurrentClearanceLevel = store.store.getState().user.userCurrentClearanceLevel;

  try {
    const service =
      incidentFilter.ibrsListCurrentView === 'ibrsCardView'
        ? client.service('rms-ibrs-incident')
        : client.service('rms-ibrs-submitted-incidents');
    service.timeout = 50000;

    const result = await service.find({
      query: {
        page,
        rowsPerPage: rowsPerPage,
        clearanceLevel: parseInt(userCurrentClearanceLevel.Level) || 0,

        beginningFrom: incidentFilter?.beginningFrom
          ? formatSaveDateFrom(incidentFilter?.beginningFrom)
          : null,
        endingTo: incidentFilter?.endingTo ? formatSaveDateTo(incidentFilter?.endingTo) : null,
        quickFilter: incidentFilter?.quickFilter || null,
        reportStatus: incidentFilter?.reportStatus || null,
        ibrStatus: incidentFilter?.ibrStatus || null,
        sortBy: incidentFilter?.sortBy,
        isViewError: incidentFilter?.isViewError ? 1 : 0,
        isViewNoError: incidentFilter?.isViewNoError ? 1 : 0,
        sortByDirection: incidentFilter?.sortByDirection,
        partyName: incidentFilter?.filterForm?.partyName?.FullName || null,
        partyType: incidentFilter?.filterForm?.partyType || null,
        ptsCityID: incidentFilter?.filterForm?.ptsCityID || null,
        ptsAddressID: incidentFilter?.filterForm?.ptsAddressID || null,
        streetName: incidentFilter?.filterForm?.streetName || null,
        streetType: incidentFilter?.filterForm?.streetType || null,
        predirectional: incidentFilter?.filterForm?.predirectional || null,
        postdirectional: incidentFilter?.filterForm?.postdirectional || null,
        unitType: incidentFilter?.filterForm?.unitType || null,
        unitNumber: incidentFilter?.filterForm?.unitNumber || null,
        lastName: incidentFilter?.filterForm?.lastName || null,
        firstName: incidentFilter?.filterForm?.firstName || null,
        middleName: incidentFilter?.filterForm?.middleName || null,
        zone: incidentFilter?.filterForm?.zone || null,
        cadEventNumber: incidentFilter?.filterForm?.cadEventNumber || null,
        status: incidentFilter?.filterForm?.status || null,
        tagType: incidentFilter?.filterForm?.tagType || null,
        tagValue: incidentFilter?.filterForm?.tagValue || null,
        offense: incidentFilter?.filterForm?.offense || null,
        disposition: incidentFilter?.filterForm?.disposition || null,
        lowBlock: incidentFilter?.filterForm?.lowBlock || null,
        highBlock: incidentFilter?.filterForm?.highBlock || null,
        contactMethod: incidentFilter?.filterForm?.contactMethod || null,
        contactInfo: incidentFilter?.filterForm?.contactInfo || null,
        agencyId: agencyID,
      },
    });
    let activeList = [];

    result.map((r) => {
      activeList.push({
        agencyID: r.AgencyID,
        callType: r.CallType,
        completed: r.Completed,
        cancelled: r.Cancelled,
        dispatched: r.Dispatched,
        createdBy: r.CreatedBy,
        occurred: r.Occurred,
        updated: r.Updated,
        updatedBy: r.UpdatedBy,
        received: r.Received,
        enroute: r.Enroute,
        primaryAddress: r.FullAddress,
        eventType: r.CallType,
        incidentId: r.ptsIncidentID,
        CustomId: r.IncidentID,
        ptsIncidentId: r.ptsIncidentID,
        IsJuvenileReport: r.IsJuvenileReport,
        parties: r.Parties ? JSON.parse(r.Parties) : [],
        properties: r.Properties ? JSON.parse(r.Properties) : [],
        offenses: r.Offenses ? JSON.parse(r.Offenses) : [],
        statuses: r.Statuses ? JSON.parse(r.Statuses) : [],
        synopsis: r.Synopsis ? r.Synopsis : '',
        tags: r.Tags ? JSON.parse(r.Tags) : [],
        nibrsErrors: r.IBRS ? JSON.parse(r.IBRS) : [],
      });
    });

    return activeList;
  } catch (error) {
    if (error.code === 401) {
      console.log('Unauthenticated');
    } else {
      console.log(error);
    }
    return [];
  }
};
export const setIbrsIncidents = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_ACTIVE_INCIDENTS, payload: value });
  };
};

export const setExpandOrNotValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_EXPAND_OR_NOT_VALUE, payload: value });
  };
};

export const setBeginningFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_BEGINNING_FROM_DATE, payload: date });
  };
};

export const setEndingToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_ENDING_TO_DATE, payload: date });
  };
};

export const setBeginningToEndingTimePeriod = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_BEGINNING_TO_ENDING_TIME_PERIOD, payload: value });
  };
};

export const setIbrStatusValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_STATUS_VALUE, payload: value });
  };
};

export const setReportStatusValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_REPORT_STATUS_VALUE, payload: value });
  };
};

export const setApprovalStatusValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_APPROVAL_STATUS_VALUE, payload: value });
  };
};

export const setQuickFilterValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_QUICK_FILTER_VALUE, payload: value });
  };
};

export const setGroupByValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_GROUP_BY_VALUE, payload: value });
  };
};

export const setSortByValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_SORT_BY_VALUE, payload: value });
  };
};

export const setIbrsListCurrentView = (view) => {
  return (dispatch) => {
    dispatch({ type: SET_IBRS_LIST_CURRENT_VIEW, payload: view });
  };
};

export const setSortByDirection = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_SORT_BY_DIRECTION, payload: value });
  };
};

export const setIsViewError = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_VIEW_ERROR, payload: value });
  };
};

export const setIsViewNoError = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_VIEW_NO_ERROR, payload: value });
  };
};

export const setFilterFormValues = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_IBR_FILTER_FORM_VALUES, payload: value });
  };
};

export const clearAllFilters = () => {
  const filterValue = {
    beginningFrom: null,
    endingTo: null,
    beginningToEndingTimePeriod: '',
    ibrStatus: '',
    reportStatus: '',
    quickFilter: '',
    filterForm: {
      placeOrAddress: '',
      lowBlock: '',
      highBlock: '',
      predirectional: '',
      streetName: '',
      postdirectional: '',
      streetType: '',
      unitType: '',
      unitNumber: '',
      city: '',
      zone: '',
      partyType: '',
      partyName: null,
      lastName: '',
      firstName: '',
      middleName: '',
      contactMethod: '',
      contactInfo: '',
      cadEventNumber: '',
      status: '',
      tagType: '',
      tagValue: '',
      offense: '',
      disposition: '',
    },
  };

  return (dispatch) => {
    dispatch({ type: CLEAR_ALL_IBRS_FILTERS, payload: filterValue });
  };
};

export default function reducer(
  state = {
    ibrsFilter: {
      expandOrNotValue: false,
      beginningFrom: null,
      endingTo: null,
      beginningToEndingTimePeriod: '',
      ibrStatus: '',
      reportStatus: '',
      approvalStatus: '',
      quickFilter: '',
      groupBy: '',
      sortBy: 'Incident Number',
      ibrsListCurrentView: 'ibrsCardView',
      isViewError: false,
      isViewNoError: false,
      sortByDirection: 'DESC',
      ibrsIncidents: [],
      totalIncidents: 0,
      filterForm: {
        placeOrAddress: '',
        lowBlock: '',
        highBlock: '',
        predirectional: '',
        streetName: '',
        postdirectional: '',
        streetType: '',
        unitType: '',
        unitNumber: '',
        city: '',
        zone: '',
        partyType: '',
        partyName: null,
        lastName: '',
        firstName: '',
        middleName: '',
        contactMethod: '',
        contactInfo: '',
        cadEventNumber: '',
        status: '',
        tagType: '',
        tagValue: '',
        offense: '',
        disposition: '',
      },
    },
  },
  action
) {
  switch (action.type) {
    case SET_IBR_EXPAND_OR_NOT_VALUE:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, expandOrNotValue: action.payload },
      };
    case SET_BEGINNING_FROM_DATE:
      return { ...state, ibrsFilter: { ...state.ibrsFilter, beginningFrom: action.payload } };
    case SET_ENDING_TO_DATE:
      return { ...state, ibrsFilter: { ...state.ibrsFilter, endingTo: action.payload } };
    case SET_BEGINNING_TO_ENDING_TIME_PERIOD:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, beginningToEndingTimePeriod: action.payload },
      };
    case SET_IBR_STATUS_VALUE:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, ibrStatus: action.payload },
      };
    case SET_REPORT_STATUS_VALUE:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, reportStatus: action.payload },
      };
    case SET_APPROVAL_STATUS_VALUE:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, approvalStatus: action.payload },
      };
    case SET_IBR_QUICK_FILTER_VALUE:
      return { ...state, ibrsFilter: { ...state.ibrsFilter, quickFilter: action.payload } };
    case SET_IBR_GROUP_BY_VALUE:
      return { ...state, ibrsFilter: { ...state.ibrsFilter, groupBy: action.payload } };
    case SET_IBR_SORT_BY_VALUE:
      return { ...state, ibrsFilter: { ...state.ibrsFilter, sortBy: action.payload } };
    case SET_IBRS_LIST_CURRENT_VIEW:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, ibrsListCurrentView: action.payload },
      };
    case SET_IBR_SORT_BY_DIRECTION:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, sortByDirection: action.payload },
      };
    case SET_IS_VIEW_ERROR:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, isViewError: action.payload },
      };
    case SET_IS_VIEW_NO_ERROR:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, isViewNoError: action.payload },
      };
    case SET_IBR_ACTIVE_INCIDENTS:
      return {
        ...state,
        ibrsIncidents: action.payload,
      };
    case SET_TOTAL_IBR_ACTIVE_INCIDENTS:
      return {
        ...state,
        totalIncidents: action.payload,
      };
    case SET_IBR_FILTER_FORM_VALUES:
      return {
        ...state,
        ibrsFilter: { ...state.ibrsFilter, filterForm: { ...action.payload } },
      };
    case CLEAR_ALL_IBRS_FILTERS:
      return {
        ...state,
        ibrsFilter: {
          ...state.ibrsFilter,
          beginningFrom: action.payload.beginningFrom,
          endingTo: action.payload.endingTo,
          beginningToEndingTimePeriod: action.payload.beginningToEndingTimePeriod,
          ibrStatus: action.payload.ibrStatus,
          reportStatus: action.payload.reportStatus,
          quickFilter: action.payload.quickFilter,
          filterForm: action.payload.filterForm,
        },
      };
  }

  return state;
}
