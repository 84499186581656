import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeWarrants } from 'reducers/PersonDialogsReducer';
import Dialog from 'components/RMSDialog';
import { handleError } from 'reducers/ErrorReducer';
import WarrantCard from 'components/WarrantCard';

const useStyles = makeStyles((theme) => ({
  loading: {
    width: 500,
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

function WarrantDialog(props) {
  const classes = useStyles();
  const { Name, warrants } = props.data;

  const close = () => {
    props.closeWarrants();
  };

  return (
    <Dialog toolbar onClose={close} title={`Warrants: ${Name}`}>
      <WarrantCard warrants={warrants} />
    </Dialog>
  );
}

export default connect(null, {
  closeWarrants,
  handleError,
})(WarrantDialog);
