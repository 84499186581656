import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import formStyles from 'utils/formStyles';
import { IconButton } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useState } from 'react';

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0,
};

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  inputRoot: {
    color: '#D33D1D',
  },
}));

function RMSAutocomplete2(props) {
  const classes = useStyles();
  const {
    disabled,
    error,
    value,
    options,
    className,
    label,
    onChange,
    helperText,
    onAdd,
    compact,
    inputRef,
    formType,
    getOptionSelected = (option, value) => option.Code === value.Code,
    getOptionLabel = (option) => (option.Code ? option.Code : value),
    renderOption = (option) => (
      <span>
        <strong>{option.Code}</strong>
        {!!option.Description && <> - {option.Description}</>}
      </span>
    ),
    table = '',
    autoComplete,
    autoSelect,
    autoHighlight,
  } = props;

  const [populateDefaultValue, setPopulateDefaultValue] = useState(() =>
    formType ? formType === 'add' : false
  );

  const getValue = () => {
    if (value || !populateDefaultValue) return value;

    // when there is no value saved for that field in db,
    // we should select the option for which IsDefault = 1:
    const defaultEl = options.find((el) => el.IsDefault);

    // no IsDefault = 1 exist in options; so keep the current value as it is:
    if (!defaultEl) return value;
    onChange(null, defaultEl);
    return defaultEl;
  };

  const filter = createFilterOptions({
    stringify: (option) =>
      (option.Code || '') + (option?.Description || '') + (option?.AgencyID || ''),
  });
  const filterOptions =
    options?.length && (options[0].Code || options[0].Description || options[0].AgencyID)
      ? filter
      : undefined;

  const customhelperText = compact
    ? undefined
    : helperText
    ? helperText
    : typeof error === 'string'
    ? error
    : ' ';

  //// applying style for codeWarrantDispositions /////
  const getClass = () => {
    return table === 'codeWarrantDispositions' && value && value.Code === 'ACT'
      ? classes.inputRoot
      : {};
  };
  return (
    <Autocomplete
      options={options || []}
      classes={{
        option: classes.option,
        root: classes.autocomplete,
        inputRoot: getClass(),
      }}
      className={className}
      disabled={disabled}
      autoHighlight
      autoComplete
      autoSelect={false}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptions}
      renderOption={renderOption}
      onChange={(event, value) => {
        if (populateDefaultValue) setPopulateDefaultValue(false);
        onChange(event, value);
      }}
      value={getValue()}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={customhelperText}
          label={label}
          variant="outlined"
          size="small"
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'none',
            startAdornment:
              Boolean(onAdd) && !disabled ? (
                <IconButton style={btnStyle} onClick={onAdd}>
                  <AddIcon fontSize="small" />
                </IconButton>
              ) : null,
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'none',
          }}
        />
      )}
    />
  );
}

export default RMSAutocomplete2;
