export const SET_SELECTED_ATTACHMENTS_FOR_DOWNLOAD = 'SET_SELECTED_ATTACHMENTS_FOR_DOWNLOAD';

export const setSelectedAttachmentsForDownload = (attachemnts) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ATTACHMENTS_FOR_DOWNLOAD, payload: attachemnts });
  };
};

export const resetSelectedAttachmentsForDownload = () => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ATTACHMENTS_FOR_DOWNLOAD, payload: [] });
  };
};

export default function reducer(
  state = {
    selectedDownloadAttachments: [],
  },
  action
) {
  switch (action.type) {
    case SET_SELECTED_ATTACHMENTS_FOR_DOWNLOAD:
      return {
        ...state,
        selectedDownloadAttachments: action.payload,
      };
  }

  return state;
}
