import * as Sentry from '@sentry/react';

import { setUserAuthenticated } from './UserReducer';
import { SNACKBAR_ENQUEUE } from './NotifierReducer';

const getKey = () => new Date().getTime() + Math.random();

const getNotificationOptions = (msgType = 'error') => {
  return {
    key: new Date().getTime() + Math.random(),
    variant: msgType,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  };
};

const getMessage = (message, msgType) => {
  return { message, options: getNotificationOptions(msgType) };
};

const getSnackbar = (notification) => {
  return {
    type: SNACKBAR_ENQUEUE,
    notification,
    key: getKey(),
  };
};

let lastTime = 0;
let suppressErrors = false;
let errTimeout = 0;

const suppressErr = () => {
  clearTimeout(errTimeout);
  suppressErrors = true;
  errTimeout = setTimeout(() => {
    suppressErrors = false;
  }, 2000);
};

const suppressErrClasses = ['Timeout', 'timeout', 'not-authenticated'];

export const handleError = (error, message) => (dispatch) => {
  let showMsg = true;
  if (suppressErrors || suppressErrClasses.indexOf(error.className) !== -1) {
    suppressErr();
    showMsg = false;
  } else {
    console.log('error: ', error);
  }

  const time = new Date().getTime();
  showMsg && Sentry.captureException(error);

  if (error?.code === 440) {
    const notification = getMessage(error.message);
    dispatch(getSnackbar(notification));
    return;
  }

  if (error && error.code === 401) {
    const notification = getMessage("You've been logged out");
    dispatch(getSnackbar(notification));
    dispatch(setUserAuthenticated());
    dispatch(setUserData(null));
    clearTimeout(errTimeout);
  } else if (error && error.code === 408 && time - lastTime > 50000) {
    const notification = getMessage('Network error, please check your connection.');
    showMsg && dispatch(getSnackbar(notification));
    lastTime = time;
  }
  if (!message && error.message) {
    message = error.message;
  }
  if (message) {
    const { errors } = error;
    if (errors && errors.length) {
      if (errors.reduce((r, v) => (typeof v !== 'string' ? false : r), true)) {
        message += ': ' + errors.join('; ');
      } else if (errors.reduce((r, v) => (typeof v !== 'object' || !v.message ? false : r), true)) {
        message += ': ' + errors.map((e) => e.message).join('; ');
      }
    }

    const notification = getMessage(message);
    showMsg && dispatch(getSnackbar(notification));
  }
};
