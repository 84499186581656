import moment from 'moment';
import { getService } from './service';
import { formatSaveDateFrom, formatSaveDateTo } from './TimeReducer';
import { addCreatedUpdatedBy, addUpdatedBy } from 'utils/functions';

export const searchNotifications = async (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-notifications');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchPlaceAddresses = async (ptsPlaceID) => {
  const service = getService('cad-search-place-address');
  const result = await service.find({
    query: { ptsPlaceID, IsDeleted: 0 },
  });

  if (!result?.data?.length) return [];
  return result.data.map((r) => r.Address);
};

export const searchUnitStatus = (search = '', dateFrom, dateTo, filter, unit) => {
  const service = getService('cad-search-unit-status');
  return service.find({
    query: {
      search,
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
      unit,
    },
  });
};

export const getUnitAction = (ptsActionID) => {
  const service = getService('cad-search-unit-status');
  return service.get(ptsActionID);
};

export const saveUnitAction = (data) => {
  const service = getService('cad-search-unit-status');
  return service.patch(data.ptsActionID, data);
};

export const searchAdvancedEvents = (
  search = '',
  dateFrom,
  dateTo,
  filter,
  ViewDeleted = false
) => {
  const service = getService('cad-search-advanced-events');
  return service.find({
    query: {
      search,
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
      ViewDeleted,
    },
  });
};

export const searchEvents = (search = '', dateFrom, dateTo, filter, ViewDeleted = false) => {
  const service = getService('cad-search-events');
  return service.find({
    query: {
      search,
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
      ViewDeleted,
    },
  });
};

export const searchNotes = (search = '', dateFrom, dateTo, filter) => {
  const service = getService('cad-search-notes');
  return service.find({
    query: {
      search,
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchUnits = () => {
  const service = getService('cad-search-units');
  return service.find();
};

export const addUnit = (data) => {
  const service = getService('cad-search-units');
  return service.create(data);
};

export const editUnit = (ptsUnitID, data) => {
  const service = getService('cad-search-units');
  return service.patch(ptsUnitID, data);
};

export const searchAgencies = (text) => {
  const service = getService('cad-search-agencies');
  return service.find({ query: { type: 'rms', text } });
};

export const searchEmployees = (search = '', offset, limit) => {
  const service = getService('cad-search-employees');
  return service.find({ query: { search, offset, limit } });
};

export const searchNames = (search = '', offset, limit, ViewDeleted) => {
  const service = getService('cad-search-names');
  return service.find({ query: { search, offset, limit, application: 'RMS', ViewDeleted } });
};

export const getName = (ptsNameID) => {
  const service = getService('cad-search-names');
  return service.get(ptsNameID);
};

export const updateName = (ptsNameID, data) => {
  const service = getService('cad-search-names');
  return service.patch(ptsNameID, data);
};

export const searchCredentials = (search = '', offset = 0, limit = 1000) => {
  const service = getService('cad-search-credentials');
  return service.find({ query: { search, offset, limit } });
};

export const searchImages = (pageNumber, text, limit) => {
  const service = getService('cad-search-images');
  return service.find({ query: { pageNumber, type: 'rms', text, limit } });
};

export const searchSops = () => {
  const service = getService('cad-search-sops');
  return service.find();
};

export const searchPhoneNumbers = (
  search,
  dateFrom,
  dateTo,
  startTime,
  endTime,
  offset,
  limit,
  type = 'rms'
) => {
  const service = getService('cad-search-phone-numbers');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000', //set default date
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      startTime,
      endTime,
      offset,
      limit,
      type,
    },
  });
};

export const searchPeople = (
  search = '',
  filterData,
  ViewDeleted = false,
  MatchNickName = false
) => {
  const searchData = { search, ...filterData };
  const service = getService('cad-search-people');
  return service.find({
    query: { searchData, ViewDeleted, MatchNickName, MatchMarksDetails: true },
  });
};

export const deletePerson = (id) => {
  const service = getService('cad-search-people');
  return service.remove(id);
};

export const searchPeople2 = (
  search = '',
  ViewDeleted = false,
  offset,
  limit,
  searchByPersonID
) => {
  const service = getService('cad-search-people2');
  return service.find({ query: { search, ViewDeleted, offset, limit, searchByPersonID } });
};

export const searchAddresses = (
  search = '',
  ViewDeleted = false,
  showInEventLookup = false,
  offset,
  limit
) => {
  const service = getService('cad-search-addresses');
  return service.find({ query: { search, ViewDeleted, showInEventLookup, offset, limit } });
};

export const searchWarrants = (
  text = '',
  issueFromDate = null,
  issueToDate = null,
  onlyShowActiveWarrant = null,
  offset,
  limit
) => {
  const service = getService('rms-warrant');
  return service.find({
    query: {
      type: 'warrant',
      data: {
        text,
        issueFromDate,
        issueToDate,
        onlyShowActiveWarrant,
        offset,
        limit,
      },
    },
  });
};

export const searchAddress = (ptsAddressID) => {
  const service = getService('cad-search-addresses');
  return service.get(ptsAddressID);
};

export const searchAdvancedAddresses = (
  search = '',
  ViewDeleted = false,
  showInEventLookup = false
) => {
  const service = getService('cad-search-advanced-addresses');
  return service.find({ query: { search, ViewDeleted, showInEventLookup } });
};

export const searchItems = () => {
  const service = getService('cad-search-items');
  return service.find();
};

export const searchPlaces = (search = '', ViewDeleted = false, offset, limit) => {
  const service = getService('cad-search-places');
  return service.find({ query: { search, ViewDeleted, offset, limit } });
};

export const removePlace = (id) => {
  const service = getService('cad-search-places');
  return service.remove(id);
};

export const searchVehicles = (search = '', ViewDeleted = false) => {
  const service = getService('cad-search-vehicles');
  return service.find({ query: { search, ViewDeleted } });
};

export const searchSpecificVehicle = (ptsVehicleID) => {
  const service = getService('cad-pts-vehicle');
  return service.get(ptsVehicleID);
};

export const editVehicle = (ptsVehicleID, data) => {
  const service = getService('cad-pts-vehicle');
  return service.patch(ptsVehicleID, data);
};

export const addVehicle = (data) => {
  const service = getService('cad-search-vehicles');
  return service.create(data);
};

export const removeVehicle = (data) => {
  const service = getService('cad-search-vehicles');
  return service.remove(data);
};

export const searchVehicleRegistrations = (ptsVehicleID) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.find({
    query: { ptsVehicleID },
  });
};

export const addVehicleReg = (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('cad-pts-vehicle-reg');
  return service.create(saveData);
};

export const updateVehicleReg = (id, data) => {
  const saveData = addUpdatedBy(data);
  const service = getService('cad-pts-vehicle-reg');
  return service.patch(id, saveData);
};

export const deleteVehicleReg = (id) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.remove(id);
};

export const searchVehicleTowings = (
  search = '',
  dateFrom,
  dateTo,
  offset,
  limit,
  type = 'rms'
) => {
  const service = getService('cad-search-vehicle-towings');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      offset,
      limit,
      type,
    },
  });
};

export const searchZones = (AgencyID, offset, limit) => {
  const service = getService('cad-search-zones');
  return service.find({ query: { AgencyID, offset, limit } });
};

export const getPtsPerson = (ptsPersonID) => {
  const service = getService('cad-search-people');
  return service.get(ptsPersonID);
};

export const getPtsPlace = (ptsPlaceID) => {
  const service = getService('places');
  return service.get(ptsPlaceID);
};

export const updatePtsPlace = (ptsPlaceID, data) => {
  const service = getService('cad-search-places');
  return service.patch(ptsPlaceID, data);
};

export const addPlace = (data) => {
  const service = getService('cad-search-places');
  return service.create(data);
};

export const searchRotations = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-rotation-history');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchQueries = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-ncic-queries');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const removeUnitStatus = (ptsActionID) => {
  const service = getService('cad-search-unit-status');
  return service.remove(ptsActionID);
};

export const editCredential = (ptsCredentialID, data) => {
  const service = getService('cad-search-credentials');
  return service.patch(ptsCredentialID, data);
};

export const addOrEditPerson = (ptsPersonID, data) => {
  const service = getService('cad-search-people');

  data = { ...data, application: 'rms' };

  return ptsPersonID && ptsPersonID !== true
    ? service.patch(ptsPersonID, data)
    : service.create(data);
};

export const getContactMethods = async (id, PartyType = 'Person') => {
  const service = getService('record-person-contact');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: id,
    PartyType: PartyType,
    Info: {
      $ne: '',
    },
  };

  const result = await service.find({
    query: q,
  });
  // console.log('result', result);
  return result;
};
export const getPeopleCredentials = async (personId) => {
  const service = getService('record-person-credentials');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
    Number: {
      $ne: '',
    },
  };

  const result = await service.find({
    query: q,
  });
  // console.log('result', result);
  return result;
};
export const getPeopleEmployments = async (personId) => {
  const service = getService('record-person-employment');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};

export const getPeopleAssociate = async (ptsPersonID) => {
  const service = getService('cad-person-associate');
  const result = await service.find({ query: { ptsPersonID } });
  // console.log('emp result', result);
  return result;
};

export const createPeopleAssociate = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('record-person-associates');
  await service.create(saveData);
};

export const updatePeopleAssociate = async (ptsAssociateID, data) => {
  const saveData = addUpdatedBy(data);
  const service = getService('record-person-associates');
  return await service.update(ptsAssociateID, saveData);
};

export const getPeopleFeatures = async (personId) => {
  const service = getService('record-person-feature');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });
  // console.log('emp result', result);
  return result;
};

export const getPeopleNames = async (personId) => {
  const service = getService('record-person-name');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });
  // console.log('emp result', result);
  return result;
};

export const createPersonName = async (data) => {
  const service = getService('record-person-name');
  const saveData = addCreatedUpdatedBy(data);
  return service.create(saveData);
};

export const editPersonName = async (id, data) => {
  const service = getService('record-person-name');
  const saveData = addUpdatedBy(data);
  return service.patch(id, saveData);
};

export const deletePersonName = async (id) => {
  const service = getService('record-person-name');
  return service.remove(id);
};

export const getPeopleVehicle = async (personId) => {
  const service = getService('record-person-vehicle-registration');
  let query = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: personId,
  };

  const result = await service.find({ query });
  return result;
};

export const getPeopleTags = async (ptsPersonID) => {
  const service = getService('rms-tags-v2');
  const query = {
    ptsParentID: ptsPersonID,
    ParentType: 'Person',
    $limit: 0,
  };
  return service.find({ query });
};

export const savePersonTags = async (data) => {
  const service = getService('rms-tags-v2');
  return data.ptsTagID
    ? service.update({ ptsTagID: data.ptsTagID, IsDeleted: 0 }, data)
    : service.create(data);
};

export const removePersonTags = async (query) => {
  const service = getService('rms-tags-v2');
  return service.remove(null, { query });
};

export const getPeopleAddresses = async (data) => {
  const service = getService('record-person-address');
  const query = data;
  return service.find({ query });
};
// export const getPlaceAddresses = async (ptsPlaceID) => {
//   const service = getService('place-addresses');
//   const query = {
//     ptsPlaceID,
//   };
//   return service.find({ query });
// };

export const savePersonAddress = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('record-person-address');
  return service.create(saveData);
};

export const updatePersonAddress = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('record-person-address');
  return service.update(
    { ptsPersonID: data.ptsPersonID, ptsAddressID: data.ptsAddressID },
    saveData
  );
};

export const deletePersonAddress = async (personId, addressId) => {
  const service = getService('record-person-address');
  return service.remove({ ptsPersonID: personId, ptsAddressID: addressId });
};

export const getplaceAddress = async (ptsPlaceID) => {
  const service = getService('cad-search-place-address');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPlaceID: ptsPlaceID,
  };

  const result = await service.find({
    query: q,
  });
  return result;
};

export const getPlaceAddressData = async (ptsPlaceID, ptsAddressID) => {
  const service = getService('cad-search-place-address');

  const result = await service.get({
    ptsPlaceID,
    ptsAddressID,
  });

  return result;
};

export const getPeopleSops = async (personId, ParentType = 'Person') => {
  const service = getService('record-person-sops');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: personId,
    ParentType: ParentType,
  };

  const result = await service.find({
    query: q,
  });
  //console.log('sop result', result);
  return result;
};

export const savePlaceAddress = async (data) => {
  const service = getService('cad-search-place-address');
  return service.create(data);
};

export const updatePlaceAddress = async (data) => {
  const { IsPrimary, ptsPlaceID, ptsAddressID } = data;
  const service = getService('cad-search-place-address');
  return service.patch(null, { IsPrimary }, { query: { ptsPlaceID, ptsAddressID } });
};

export const addPersonEmployment = async (data) => {
  const service = getService('cad-search-people-employment');
  return service.create(data);
};

export const editPersonEmployment = async (ptsEmploymentID, data) => {
  const service = getService('cad-search-people-employment');
  return service.patch(ptsEmploymentID, data);
};

export const getUnitsResources = async () => {
  const service = getService('settings-resources');
  const result = await service.get({ type: 'units-resources' });
  return result;
};

export const saveUnitPerson = (data) => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-person', data });
};

export const removeUnitResource = (ptsResourceID) => {
  const service = getService('settings-resources');
  return service.patch(ptsResourceID, { type: 'remove-unit-resource', data: { ptsResourceID } });
};

export const editUnitResource = (ptsResourceID, data) => {
  const service = getService('settings-resources');
  return service.patch(ptsResourceID, { type: 'edit-unit-resource', data });
};

export const saveUnitVehicle = (data) => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-vehicle', data });
};

export const getPersonDnaSamples = (ptsPersonID) => {
  const service = getService('cad-person-dna-samples');
  return service.get(ptsPersonID);
};

export const getPlaceByID = (ptsPlaceID) => {
  const service = getService('cad-search-places');
  return service.get(ptsPlaceID);
};

export const createDnaSample = (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('cad-person-dna-samples');
  return service.create(saveData);
};

export const editDnaSample = (id, data) => {
  const saveData = addUpdatedBy(data);
  const service = getService('cad-person-dna-samples');
  return service.patch(id, saveData);
};

export const removeDnaSample = (id) => {
  const service = getService('cad-person-dna-samples');
  return service.remove(id);
};

export const addPersonFeature = (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('cad-person-features');
  return service.create(saveData);
};

export const editPersonFeature = (ptsFeatureID, data) => {
  const saveData = addUpdatedBy(data);
  const service = getService('cad-person-features');
  return service.patch(ptsFeatureID, saveData);
};

export const getPersonImages = (ptsPersonID, IsMarkOnly) => {
  const service = getService('cad-person-photo');
  return service.get(ptsPersonID, {
    query: {
      IsMarkOnly,
    },
  });
};

export const editPersonImage = (ptsImageID, data) => {
  const service = getService('cad-person-photo');
  return service.patch(ptsImageID, data);
};

export const removePersonImage = (ptsImageID) => {
  const service = getService('cad-person-photo');
  return service.remove(ptsImageID);
};

export const getPersonRecordDetails = (ptsPersonID, employeeRelated = false) => {
  const service = getService('search-person');
  return service.get(ptsPersonID, { employeeRelated });
};

export const removePlaceAddress = (data) => {
  const service = getService('cad-place-address');
  return service.remove(data);
};

export const getUnitResource = (ptsResourceID) => {
  const service = getService('cad-search-resources');
  return service.get(ptsResourceID);
};

export const getUnit = (ptsUnitID) => {
  const service = getService('cad-search-units');
  return service.get(ptsUnitID);
};

export const getPlaceDetails = (ptsPlaceID) => {
  const service = getService('cad');
  return service.get({
    type: 'party-place-details',
    data: { ptsPlaceID },
  });
};

export const getZoneSops = async (IntCode, ParentType = 'Zone') => {
  const service = getService('record-person-sops');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: IntCode,
    ParentType: ParentType,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};
