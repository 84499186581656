import { getService } from '../service';

function bufferToBlob(buffer, mime) {
  return new Blob([buffer], { type: mime });
}

// Types: Event, Person, Warrants, Address, FIREARM, ITEM, JEWELRY, STRUCTURE, SUBSTANCE,
// EVIDENCE, INCIDENTPERSON, INCIDENTPROPERTY, VEHICLE,
export const uploadAttachment = (id, file, type = 'Event', descr = '', codeAttachmentType) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    try {
      reader.onload = async function () {
        try {
          const service = getService('cad-attachments');
          const result = await service.create({
            parentType: type,
            ptsParentID: id,
            fileName: file.name,
            fileType: file.name.split('.')[1],
            data: reader.result.split(',')[1],
            description: descr,
            codeAttachmentType: codeAttachmentType,
          });
          resolve(result);
        } catch (err) {
          reject(err);
        }
      };
    } catch (err) {
      reject(err);
    }
  });
};

export const uploadRMSAttachment = (id, file, type, descr = '', user, codeAttachmentType) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    try {
      reader.onload = async function () {
        try {
          const service = getService('rms-attachments');
          const result = await service.create({
            parentType: type,
            ptsParentID: id,
            fileName: file.name,
            fileType: file.name.split('.')[1],
            data: reader.result.split(',')[1],
            description: descr,
            codeAttachmentType,
            CreatedBy: user,
            UpdatedBy: user,
          });
          resolve(result);
        } catch (err) {
          reject(err);
        }
      };
    } catch (err) {
      reject(err);
    }
  });
};

export const uploadPersonPhoto = (data) => {
  const {
    file,
    ptsPersonID,
    ImageTypeCode,
    IsMark,
    Location,
    Gang,
    Description,
    MarkType,
    IsDefault,
  } = data;
  const { name } = file;
  const fileName = name ? file.name.substring(0, 49) : 'Snapshot';
  const fileType = name ? file.name.split('.')[1] : 'png';
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    try {
      reader.onload = async function () {
        const service = getService('cad-upload-person-photo');
        const result = await service.create({
          ptsPersonID,
          ImageTypeCode,
          IsMark,
          IsDefault,
          Location,
          Gang,
          MarkType,
          Description,
          fileName,
          fileType,
          data: reader.result.split(',')[1],
        });
        resolve(result);
      };
    } catch (err) {
      reject(err);
    }
  });
};
export const uploadPersonMarks = async (data) => {
  const service = getService('cad-upload-person-photo');
  const result = await service.create(data);
  return result;
};
export const downloadPersonPhoto = (id, name) => {
  return new Promise((resolve, reject) => {
    const service = getService('cad-upload-person-photo');
    service
      .get(id)
      .then((res) => {
        const blob = bufferToBlob(res.buffer, res.type);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        return resolve();
      })
      .catch(reject);
  });
};

export const downloadAttachment = (id, name) => {
  return new Promise((resolve, reject) => {
    const service = getService('cad-attachments');
    service
      .get(id)
      .then((res) => {
        const blob = bufferToBlob(res.buffer, res.type);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        return resolve();
      })
      .catch(reject);
  });
};

export const getAttachmentsRMS = async (id, type) => {
  const service = getService('rms-attachments');

  const result = await service.find({
    query: {
      id: id,
      type: type,
    },
  });
  let atts = [];
  result?.map((r) => {
    atts.push({
      ptsAttachmentId: r.ptsAttachmentID,
      name: r.FileName,
      type: r.FileType,
      dataURL: r.FileObject,
      description: r.Description,
      updatedBy: r.UpdatedBy,
      updated: r.Updated,
      created: r.Created,
      createdBy: r.CreatedBy,
      ParentType: r.ParentType,
      ParentID: r.ptsParentID,
      IsDeleted: r.IsDeleted,
    });
  });

  return atts;
};

export const removeAttachment = (id) => {
  const service = getService('cad-attachments');
  return service.remove(id);
};

export const findAttachments = (type, id) => {
  const service = getService('cad-attachments');
  return service.find({ query: { ParentType: type, ptsParentID: id } });
};

export const updateAttachment = (data) => {
  const service = getService('cad-attachments');
  return service.patch(data.ptsAttachmentID, data);
};
