import React, { useState, Fragment, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Hidden,
  Avatar,
  Box,
  Badge,
  Menu,
  List,
  ListItem,
  Drawer,
  Divider,
  FormControl,
  Select,
  InputLabel,
  Card,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Typography,
  Grid,
  MenuItem,
  MenuList,
  Collapse,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { ListItemButton } from '@mui/material';
import MenuBulletIcon from '@material-ui/icons/FiberManualRecord';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuIcon from '@material-ui/icons/Menu';
import grey from '@material-ui/core/colors/grey';

import menuItems from '../config/configureMenu';
import AboutDialog from './AboutDialog';
import RMSTopbarShareDialog from './topbarShareDialog';
import RMSConfirmationModal from './RMSModals/RMSConfirmationModal';
import UnreadBulletins from './UnreadBulletins';
import IconRms from '../assets/images/icon-RMS.svg';
import { colors } from '../config/colorVariables';

import { resetRecordAdvanceSearchKeyword, setRecordActive } from 'reducers/RecordsReducer';
import { setNavigationDrawerMobileState } from 'reducers/UiReducer';
import { setMode } from '../reducers/ThemeReducer';
import {
  setUserAuthenticated,
  setUserAgency,
  setUserClearanceLevel,
  setUserClearanceOptions,
  authorizeExternalApp,
} from '../reducers/UserReducer';
import { getAgencies } from '../reducers/dictionaries/CodeAgencyReducer';
import { getAccessPermission, getFullPermissions } from '../reducers/PermissionsReducer';
import { setHandleIncFormSave, setSavingDisabled } from 'reducers/ui/UiMenuReducer';
import { setWorkflows } from 'reducers/WorkFlowReducer';
import { getWFlows } from 'reducers/helpers/workflowHelper';
import { setActiveWorkflow, setFilterAllValues } from 'reducers/ui/UIHomeScreenReducer';
import { getTimePeriod, getServerTimeSettings } from 'reducers/TimeReducer';
import { formatSaveDate } from 'reducers/TimeReducer';
import {
  getAgencyAddresses,
  getAgencyOtherSettings,
  getAgencySettings,
} from 'reducers/AgencySettingsReducer';
import { getApiVer } from '../reducers/AppInfoReducer';
import {
  getEntitiesByIncidentIdV2,
  setIsFormDirty,
  setSelectedIncident,
} from 'reducers/IncReducer';
import { getAllActiveEvidence } from 'reducers/EvidenceReducer';
import { getDictionaries } from 'reducers/DictionaryReducer';
import { getAllActiveCitations } from 'reducers/CitationReducer';
import { getService } from 'reducers/service';
import { getUserPermissions } from 'reducers/PermissionsReducer';

const drawerWidth = 220;
const drawerHeight = 50;
const permissionObj = {
  persons: { permissionType: 'globals', permissionName: 'People', permissionAgency: 'any' },
  properties: { permissionType: 'rms', permissionName: 'Property', permissionAgency: 'specific' },
};

function getSettingsUrl() {
  let url = process.env.REACT_APP_SETTINGS_URL;
  if (!url) return '';
  if (url.substr(-1) !== '/') url += '/';
  url += 'settings';
  return url;
}

const searchPartiesPaths = [
  {
    path: '/search/credentials',
    label: 'Credentials',
    parent: 'persons',
  },

  {
    path: '/search/employees',
    label: 'Employees',
    permissionType: 'globals',
    permissionName: 'Employees',
    permissionAgency: 'any',
  },
  {
    path: '/search/images',
    label: 'Images',
    parent: 'persons',
  },
  {
    path: '/names',
    label: 'Names',
    parent: 'persons',
  },

  {
    path: '/persons',
    label: 'People',
    parent: 'persons',
  },

  {
    path: '/search/phone-numbers',
    label: 'Phone Numbers',
    parent: 'persons',
  },
];

const searchPropertiesPaths = [
  {
    path: '/properties',
    label: 'Property',
    parent: 'properties',
  },
  {
    path: '/search/vehicle-towings',
    label: 'Vehicle Towings',
    parent: 'properties',
  },
];

const searchLocationPaths = [
  {
    path: '/addresses',
    label: 'Addresses',
    permissionType: 'globals',
    permissionName: 'Addresses',
    permissionAgency: 'any',
  },
  {
    path: '/search/agencies',
    label: 'Agencies',
    permissionType: 'globals',
    permissionName: 'Agencies',
    permissionAgency: 'any',
  },
  {
    path: '/places',
    label: 'Places',
    permissionType: 'globals',
    permissionName: 'Places',
    permissionAgency: 'any',
  },
  {
    path: '/search/zones',
    label: 'Zones',
    permissionType: 'globals',
    permissionName: 'Zones',
    permissionAgency: 'any',
  },
];
const searchbulletinPath = [
  {
    path: '/search/bulletin',
    label: 'Bulletin',
    permissionType: 'cad',
    permissionName: 'Alerts',
    permissionAgency: 'any',
  },
];
const settingsUrl = getSettingsUrl();

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftMenu: {
    display: 'flex',
    flex: 1,
    color: 'white',
    paddingTop: '2px 0px',
  },
  items: {
    color: 'white',
    padding: '0px',
    fontSize: '17px',
  },
  drawerItems: {
    color: theme.palette.bgElements,
    padding: '0px',
    fontSize: '17px',
  },
  itemsBtn: {
    color: theme.palette.colors.label,
    padding: '7px 16px',
  },
  propertyBtn: {
    color: theme.palette.colors.label,
    paddingTop: '7px',
    paddingBottom: '7px',
    paddingLeft: '20px',
  },
  label: {
    flexDirection: 'column',
  },
  drawerItemsBtn: {
    color: colors.defaultBlue,
    fontSize: '17px',
    padding: '10px 40px',
  },
  rightMenu: {
    alignSelf: 'center',
    paddingTop: '2px 0px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.bgElements,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    zIndex: 98,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: '0px 6px',
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements,
  },
  btn: {
    width: '85px',
    height: '60px',
    border: '3px solid rgba(1, 83, 163, 0.5) !important',
    color: 'white',
  },

  btnLabel: {},
  avatar: {
    position: 'relative',

    '& i': {
      position: 'absolute',
      bottom: -18,
      left: -30,
      right: -30,
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontStyle: 'normal',
      textAlign: 'center',
    },
  },
  activeNav: {
    color: 'red',
    '& > button': {
      fontWeight: 600,
      color: grey[200],
    },
  },
  locationSearchMenu: {
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      opacity: 0.25,
    },
    '& a': {
      color: theme.palette.colorFont,
    },
  },
  searchMenu: {
    display: 'flex',
    '& h5': {
      paddingLeft: '30px',
    },
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      opacity: 0.25,
    },
    '& a': {
      color: theme.palette.colorFont,
    },
  },
  fullWidthMenu: {
    zIndex: 2,
    position: 'absolute',
    top: 70,
    left: 0,
    right: 0,
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  locationsMenuWrap: {
    position: 'absolute',
    top: 70,
    left: '22rem',
    right: 0,
    bottom: 0,
    width: '10%',
    height: '100%',
    zIndex: 2,
  },
  peopleMenuWrap: {
    position: 'absolute',
    top: 70,
    left: '12rem',
    right: 0,
    bottom: 0,
    width: '10%',
    height: '100%',
    zIndex: 2,
  },
  fwMenuWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  logoText: {
    color: theme.palette.bgElements,
  },
  propertyItemIcon: {
    color: colors.defaultBlueDarker2,
    fontSize: '10px',
  },
  outlined: {
    color: theme.palette.colors.label,
  },
  propertyItemLabel: {
    color: theme.palette.colorFont,
    paddingLeft: '5px',
  },

  listItemActive: {
    '&.active': {
      color: theme.palette.topBar.main,
      fontWeight: 900,
      textDecoration: 'underline',
      textDecorationColor: theme.palette.topBar.underline,
      textDecorationThickness: '3px',
      textUnderlineOffset: '10px',
    },
  },
  subMenuListItemActive: {
    padding: '2px 3px !important',
    '&.active': {
      color: theme.palette.topBar.submenu,
      fontWeight: 900,
    },
  },
  disabledDrawer: {
    color: theme.palette.bgElements,
    padding: '0px',
    fontSize: '17px',
    cursor: 'not-allowed !important',
  },
  disabledDrawerItems: {
    fontWeight: '100',

    fontSize: '17px',
    padding: '10px 40px',
    // color: 'currentColor',
    cursor: 'not-allowed !important',
    opacity: 0.5,
    // textDecoration: 'none',
  },
  searchMenuItem: {
    width: '100%',
  },
}));

const UserBadge = withStyles({
  badge: {
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const NavBase = React.forwardRef((props, ref) => <NavLink ref={ref} {...props} />);
const handlePermissionChecking = (btn, userAgency) => {
  if (btn.disabled) return true;
  if (btn.parent) {
    btn = { ...btn, ...(permissionObj[btn.parent] || {}) };
  }

  if (!btn.permissionName) return false;
  else {
    const { permissionName, permissionType, permissionAgency } = btn || {};
    const incidentsPermissions = getFullPermissions(
      permissionType,
      permissionName,
      permissionAgency === 'any' ? 'any' : userAgency
    );
    return incidentsPermissions.Read ? false : true;
  }
};

const TopBar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    user,
    permissions,
    wsClient,
    setMode,
    network,
    themeMode,
    setRecordActive,
    agencies,
    setUserAuthenticated,
    navigationDrawerMobileState,
    setUserAgency,
    setUserClearanceLevel,
    setUserClearanceOptions,
    setNavigationDrawerMobileState,
    resetRecordAdvanceSearchKeyword,
    setSavingDisabled,
    appInfo,
    incidentFilter,
    isFormDirty,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [totalShares, setTotalShares] = useState(0);
  const [sharesData, setSharesData] = useState(null);
  const [displayAbout, setDisplayAbout] = useState(false);
  const [userName, setUserName] = useState('');
  const [propertyMenuVisible, setPropertyMenuVisible] = useState(false);
  const [searchMenuVisible, setSearchMenuVisible] = useState(false);
  const [locationsMenuVisible, setLocationsMenuVisible] = useState(false);
  const [propertySearchMobileMenuVisible, setPropertySearchMobileMenuVisible] = useState(false);
  const [searchMobileMenuVisible, setSearchMobileMenuVisible] = useState(false);
  const [locationsMobileMenuVisible, setLocationsMobileMenuVisible] = useState(false);

  const [agenciesList, setAgenciesList] = useState([]);
  const secClearanceLevel = useSelector((state) => state.dictionary.secClearanceLevel);
  const userClearanceLevelID = useSelector(
    (state) => state.user?.userData?.user.ptsClearanceLevelID
  );
  const userDefaultAgency = useSelector((state) => state.user?.userData?.user.AgencyID);
  const userCurrentClearanceLevel = useSelector((state) => state.user.userCurrentClearanceLevel);
  const userClearanceOptions = useSelector((state) => state.user.userClearanceOptions);
  const [shareMenuIsOpen, setShareMenuIsOpen] = useState(false);
  const [userCurrentAgency, setUserCurrentAgency] = useState('');
  const [AppInfoRegistered, setAppInfoRegistered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [helpLink, setHelpLink] = useState(null);
  const [trainingLink, setTrainingLink] = useState(null);
  const [openCacheClearConfirmation, setOpenCacheClearConfirmation] = useState(false);
  const shareMenuAnchorRef = useRef(null);
  const shareMenuPrevOpen = useRef(shareMenuIsOpen);
  const [openPeopleSearchMenu, setOpenPeopleSearchMenu] = useState(false);
  const [openPeopleSearchMenuDesktop, setOpenPeopleSearchMenuDesktop] = useState(false);
  const userAgency = useSelector((state) => state.user.userAgency);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [newAgency, setNewAgency] = useState(null);
  const [disableAgencyChange, setDisableAgencyChange] = useState(false);
  const incidentsPermissions = getFullPermissions('rms', 'Incident', userAgency);
  const sharesPermission = getAccessPermission('rms', 'Shares', userAgency);
  useEffect(() => {
    const urlParams = location.pathname.split('/');
    if (urlParams[1] === 'citations' && urlParams.length > 2) {
      setDisableAgencyChange(true);
    } else if (urlParams.length > 2) {
      if (urlParams[3] === 'evidence') {
        setDisableAgencyChange(true);
      } else {
        setDisableAgencyChange(false);
      }
    } else {
      setDisableAgencyChange(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const parts = history.location.pathname.split('/');
    setRecordActive(parts[1] === 'records' || parts[1] === 'record' ? true : false);
  }, []);

  useEffect(() => {
    dispatch(getApiVer());
    getHelpUrl();
    getTrainingUrl();
  }, []);

  useEffect(() => {
    if (shareMenuPrevOpen.current === true && shareMenuIsOpen === false) {
      shareMenuAnchorRef.current.focus();
    }

    shareMenuPrevOpen.current = shareMenuIsOpen;
  }, [shareMenuIsOpen]);

  useEffect(() => {
    if (user.userData && user?.userData?.user) {
      let name = user?.userData?.user.Username;
      if (name !== userName) setUserName(name);
    }

    if (wsClient) {
      dispatch(getAgencies());

      if (user?.userData?.user.UserSettings) {
        let userSettings = JSON.parse(user?.userData?.user.UserSettings);
        userSettings.map((setting) => {
          if (setting.SettingApp === 'RMS' || setting.ApplicationName === 'RMS') {
            let { incidentFilter } = JSON.parse(setting.JsonValue);

            let changedFromDate, changedToDate;
            let occurredTimePeriod = incidentFilter?.occurredTimePeriod;
            let receivedTimePeriod = incidentFilter?.receivedTimePeriod;

            if (incidentFilter && occurredTimePeriod !== '' && occurredTimePeriod !== 'Custom') {
              const { fromDate, toDate } = getTimePeriod(occurredTimePeriod);

              changedFromDate = formatSaveDate(fromDate);
              changedToDate = formatSaveDate(toDate);

              incidentFilter.occuredFrom = changedFromDate;
              incidentFilter.occuredTo = changedToDate;
            }

            if (incidentFilter && receivedTimePeriod !== '' && receivedTimePeriod !== 'Custom') {
              const { fromDate, toDate } = getTimePeriod(receivedTimePeriod);

              changedFromDate = formatSaveDate(fromDate);
              changedToDate = formatSaveDate(toDate);

              incidentFilter.receivedFrom = changedFromDate;
              incidentFilter.receivedTo = changedToDate;
            }

            dispatch(setFilterAllValues(incidentFilter));
          }
          return setting;
        });
      }

      if (user?.userData?.user?.ptsUserID) {
        try {
          const getSharesData = async () => {
            const service = wsClient?.service('ptsshares');
            service.timeout = 200000;
            const res = await service.find({
              query: {
                ptsUserID: user?.userData?.user?.ptsUserID,
                IsDeleted: false,
                IsReaded: false,
              },
            });

            setTotalShares(res.total);
            setSharesData(res.data);
          };

          getSharesData();
        } catch (error) {
          console.log('error ', error);
        }
      }
    }
  }, [wsClient, user]);

  useEffect(() => {
    if (Object.keys(permissions.rms).length !== 0) {
      if (agencies.length > 0 && agenciesList.length === 0) {
        let a = [];
        for (let i = 0; i < agencies.length; ++i) {
          const p = getFullPermissions('rms', 'Incidents', agencies[i].AgencyID);
          if (p.Read === true) a.push(agencies[i]);
        }
        setAgenciesList(a);
      }
    } else {
      setAgenciesList(agencies);
    }
  }, [permissions, agencies]);

  useEffect(() => {
    const getSharesData = async () => {
      const service = wsClient?.service('ptsshares');
      service.timeout = 200000;
      const res = await service.find({
        query: {
          ptsUserID: user?.userData?.user?.ptsUserID,
          IsDeleted: false,
          IsReaded: false,
        },
      });

      setTotalShares(res.total);
      setSharesData(res.data);
    };

    if (wsClient) {
      try {
        const sharesService = wsClient?.service('ptsshares');
        sharesService.on('created', (data) => {
          getSharesData();
        });
        sharesService.on('patched', (data) => {
          getSharesData();
        });
        sharesService.on('removed', (data) => {
          getSharesData();
        });
        sharesService.on('error', () => console.log('Error listener'));
        sharesService.on('unhandledRejection', (reason, p) => {
          console.log('EventReducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
        });
      } catch (error) {
        console.log(error);
      }
    }

    return () => {
      if (wsClient) {
        try {
          const sharesService = wsClient?.service('ptsshares');
          sharesService.off('created');
          sharesService.off('patched');
          sharesService.off('removed');
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, [wsClient]);

  useEffect(() => {
    setSentryData();
  }, [appInfo]);

  useEffect(() => {
    if (wsClient) fetchAPi();
    setUserCurrentAgency(user.userAgency || '');
    dispatch(getAgencySettings(user.userAgency));
    dispatch(getAgencyAddresses(user.userAgency));

    dispatch(getAgencyOtherSettings(user.userAgency));
  }, [wsClient, user.userAgency]);

  async function fetchAPi() {
    const res = await getWFlows();
    const workFlowsOptions = res?.data?.map((workflow) => ({
      id: workflow?.ptsWFlowID,
      label: workflow?.Name,
      defaultForIncident: workflow?.DefaultForIncident,
    }));
    if (workFlowsOptions) {
      dispatch(setWorkflows(workFlowsOptions));
    }
  }

  const getHelpUrl = async () => {
    const service = getService('cdr');

    const data = await service.get({
      Path: 'Settings.Link.Help',
      CDRKey: 'HelpLink',
    });

    if (data && data.length > 0) setHelpLink(data[0].ValueString || '');
  };

  const getTrainingUrl = async () => {
    const service = getService('cdr');

    const data = await service.get({
      Path: 'Settings.Link.Training',
      CDRKey: 'TrainingLink',
    });

    if (data && data.length > 0) setTrainingLink(data[0].ValueString || '');
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleShareMenuToggle = () => {
    setShareMenuIsOpen((shareMenuPrevOpen) => !shareMenuPrevOpen);
  };

  const handleShareMenuClose = (event) => {
    if (shareMenuAnchorRef.current && shareMenuAnchorRef.current.contains(event.target)) {
      return;
    }

    setShareMenuIsOpen(false);
  };

  const readShare = (id, incidentID) => {
    try {
      const updateShare = async () => {
        const service = wsClient?.service('ptsshares');
        service.timeout = 200000;
        const res = await service.patch(id, { IsReaded: true });

        history.push(`/incidents/edit/${incidentID}`);
        dispatch(setSelectedIncident(incidentID));
      };
      updateShare();
    } catch (error) {
      console.log('error ', error);
    }
  };

  const setSentryData = () => {
    const { apiVersion, buildNo, buildTime, availBuildNo, availBuildTime, appVersion } = appInfo;

    if (AppInfoRegistered || apiVersion === '?' || appVersion === '?' || availBuildNo === '?') {
      // console.log('configuration failed');
      return;
    }
    // console.log('come true');
    setAppInfoRegistered(true);
    const sentryAppInfo = {
      'RMS Version': appVersion,
      'Build No': buildNo,
      'Build Time': buildTime,
      'Avail. Build No': availBuildNo,
      'Avail. Build Time': availBuildTime,
      'API Version': apiVersion,
    };
    delete sentryAppInfo.registration;
    Sentry.setContext('App version', sentryAppInfo);
    const username = user?.userData?.user?.Username || 'unknown';
    Sentry.setUser({ username });
  };

  const StyledBadge = withStyles({
    badge: {
      backgroundColor: network.online === false ? 'var(--danger)' : 'var(--success)',
      color: network.online === false ? 'var(--danger)' : 'var(--success)',
    },
  })(UserBadge);

  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeAbout = () => {
    setDisplayAbout(false);
  };

  const showAbout = () => {
    setDisplayAbout(true);
    handleClose();
  };

  const toggleMode = () => {
    setMode();
    handleClose();
  };
  const reloadLookupCodesAndPermissions = () => {
    dispatch(getServerTimeSettings());
    dispatch(getDictionaries());
    dispatch(getUserPermissions());
  };
  const agencyChange = (ev) => {
    setUserAgency(ev.target.value);
    clearanceLevelUpdateWithAgency(ev.target.value);
    dispatch(setActiveWorkflow(null));
    dispatch(getAgencySettings(ev.target.value));
    dispatch(getAgencyOtherSettings(ev.target.value));
    dispatch(getAllActiveEvidence());
    dispatch(getAllActiveCitations());
  };

  const handleAgencyChange = (ev) => {
    const urlParams = location.pathname.split('/');
    if (urlParams[1] === 'incidents' && urlParams.length > 2 && incidentsPermissions.Edit) {
      setNewAgency(ev);
      setOpenConfirmation(true);
    } else agencyChange(ev);
  };

  const handleSubmitConfirmation = () => {
    dispatch(setIsFormDirty(false));
    agencyChange(newAgency);
    setOpenConfirmation(false);
    history.push('/incidents');
  };

  const handleDirtyFormSaving = () => {
    setOpenConfirmation(false);
    dispatch(setIsFormDirty(false));
    const urlParams = location.pathname.split('/');
    if (urlParams[2] === 'edit') {
      if (urlParams.length === 4) {
        dispatch(setHandleIncFormSave());
      } else if (urlParams[4] === 'addresses') {
        dispatch(setHandleIncFormSave('address'));
      } else if (urlParams[4] === 'parties') {
        dispatch(setHandleIncFormSave('party'));
      } else if (urlParams[4] === 'vehicle') {
        dispatch(setHandleIncFormSave('vehicle'));
      } else if (urlParams[4] === 'item') {
        dispatch(setHandleIncFormSave('item'));
      } else if (urlParams[4] === 'jewelry') {
        dispatch(setHandleIncFormSave('jewelry'));
      } else if (urlParams[4] === 'firearm') {
        dispatch(setHandleIncFormSave('firearm'));
      } else if (urlParams[4] === 'structure') {
        dispatch(setHandleIncFormSave('structure'));
      } else if (urlParams[4] === 'substance') {
        dispatch(setHandleIncFormSave('substance'));
      } else if (urlParams[4] === 'offenses') {
        dispatch(setHandleIncFormSave('offense'));
      } else if (urlParams[4] === 'evidence') {
        dispatch(setHandleIncFormSave('evidence'));
      } else if (urlParams[4] === 'narratives') {
        dispatch(setHandleIncFormSave('narrative'));
      }
    } else if (urlParams[2] === 'add') {
      if (urlParams.length === 3) {
        dispatch(setHandleIncFormSave());
      } else if (urlParams[3] === 'addresses') {
        dispatch(setHandleIncFormSave('address'));
      } else if (urlParams[3] === 'parties') {
        dispatch(setHandleIncFormSave('party'));
      } else if (urlParams[3] === 'vehicle') {
        dispatch(setHandleIncFormSave('vehicle'));
      } else if (urlParams[3] === 'item') {
        dispatch(setHandleIncFormSave('item'));
      } else if (urlParams[3] === 'jewelry') {
        dispatch(setHandleIncFormSave('jewelry'));
      } else if (urlParams[3] === 'firearm') {
        dispatch(setHandleIncFormSave('firearm'));
      } else if (urlParams[3] === 'structure') {
        dispatch(setHandleIncFormSave('structure'));
      } else if (urlParams[3] === 'substance') {
        dispatch(setHandleIncFormSave('substance'));
      } else if (urlParams[3] === 'offenses') {
        dispatch(setHandleIncFormSave('offense'));
      } else if (urlParams[3] === 'evidence') {
        dispatch(setHandleIncFormSave('evidence'));
      } else if (urlParams[3] === 'narratives') {
        dispatch(setHandleIncFormSave('narrative'));
      }
    }

    agencyChange(newAgency);
    history.push('/incidents');
  };

  const clearanceLevelUpdateWithAgency = (agency) => {
    let clearanceList = secClearanceLevel?.reduce((min, current) => {
      return parseInt(current.Level) < parseInt(min.Level) ? current : min;
    });
    if (userDefaultAgency !== agency && agency) {
      setUserClearanceOptions([clearanceList]);
      setUserClearanceLevel(clearanceList);
    } else if (userDefaultAgency === agency) {
      let clearanceLevel = secClearanceLevel.find(
        (item) => item.ptsClearanceLevelID === userClearanceLevelID
      );

      if (!clearanceLevel) {
        let newClearanceLevel = secClearanceLevel[0];
        secClearanceLevel.map((item) => {
          if (item.Level < userClearanceLevelID) {
            newClearanceLevel = item;
          }
        });
        clearanceLevel = newClearanceLevel;
      }

      setUserClearanceLevel(clearanceLevel);
      clearanceList = secClearanceLevel.filter(
        (item) => parseInt(item.Level) <= parseInt(clearanceLevel.Level)
      );
      setUserClearanceOptions(clearanceList);
    }
  };

  const clearanceLevelChange = (ev) => {
    setUserClearanceLevel(ev.target.value);
    dispatch(setActiveWorkflow(null));

    dispatch(getEntitiesByIncidentIdV2());
  };

  const renderClearanceLevel = (selected) => {
    return selected?.Description || '';
  };

  const handlePropertySearchMobileMenuVisibility = () => {
    propertySearchMobileMenuVisible
      ? setPropertySearchMobileMenuVisible(false)
      : setPropertySearchMobileMenuVisible(true);
  };

  const handleSearchMobileMenuVisibility = () => {
    setSearchMobileMenuVisible(!searchMobileMenuVisible);
  };

  const handleLocationsMobileMenuVisibility = () => {
    locationsMobileMenuVisible
      ? setLocationsMobileMenuVisible(false)
      : setLocationsMobileMenuVisible(true);
  };
  const handlePeopleSearchMenuVisibility = () => {
    setOpenPeopleSearchMenu(!openPeopleSearchMenu);
  };

  const handleClearCache = () => {
    setOpenCacheClearConfirmation(true);
  };
  const handleConfirmationClick = () => {
    const auth = localStorage.getItem('auth');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('auth', auth);
    history.push('/');
    window.location.reload();
  };

  const handleCloseDialog = () => {
    setOpenCacheClearConfirmation(false);
  };
  const currentColor = () => {
    return themeMode === 'day' ? '#FFFEFC' : '#1977D4';
  };

  const getStyleForMenu = () => {
    return {
      color: currentColor(),
      fontWeight: 600,
      textDecoration: 'underline',
      textDecorationColor: currentColor(),
      textDecorationThickness: '3px',
      textUnderlineOffset: '10px',
    };
  };

  const isSearchPath = () => {
    const searchPaths = [
      ...searchbulletinPath,
      ...searchPartiesPaths,
      ...searchLocationPaths,
      ...searchPropertiesPaths,
    ];
    if (searchPaths.some((item) => item.path === location.pathname)) return getStyleForMenu();
    return {};
  };

  const renderUserMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                <Avatar sizes="44" alt={userName} />
              </StyledBadge>
            </Box>
            <div>
              <div className="font-weight-bold text-center pt-2 line-height-1">{userName}</div>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button onClick={showAbout}>
              About
            </ListItem>
            <ListItem onClick={reloadLookupCodesAndPermissions} button>
              Reload Codes & Permissions
            </ListItem>
            <ListItem onClick={handleClearCache} button>
              Clear Cache
            </ListItem>
            <ListItem
              onClick={handleOpenSettings}
              button
              component="a"
              href={settingsUrl}
              target="_settings"
              disabled={!settingsUrl}>
              Settings
            </ListItem>
            <ListItem
              onClick={handleOpenSettings}
              button
              component="a"
              href={helpLink}
              target="_settings"
              disabled={!helpLink}>
              Help
            </ListItem>
            <ListItem
              onClick={handleOpenSettings}
              button
              component="a"
              href={trainingLink}
              target="_settings"
              disabled={!trainingLink}>
              Training Academy
            </ListItem>
            <ListItem onClick={toggleMode} button>
              {themeMode === 'day' ? 'Night mode' : 'Day mode'}
            </ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Button
                onClick={() => setUserAuthenticated(false)}
                variant="outlined"
                size="medium"
                color="default">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                </span>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </ListItem>
          </List>
          <RMSConfirmationModal
            title="Confirmation!"
            text="Clearing the cache will log you out of the system, log you back in and open to the Incident screen. You will lose all unsaved progress. . Do you wish to proceed?"
            saveBtnName="Yes"
            noBtnName="No"
            open={openCacheClearConfirmation}
            closeDialog={handleCloseDialog}
            handleModalSave={handleConfirmationClick}
          />
        </div>
      </Menu>
    );
  };

  const handleMobileMenuClose = () => {
    setPropertySearchMobileMenuVisible(false);
    setNavigationDrawerMobileState();
    setSavingDisabled(false);
  };

  const handleLocationsMobileMenuClose = () => {
    setLocationsMobileMenuVisible(false);
    setNavigationDrawerMobileState();
    setSavingDisabled(false);
  };

  const handleSearchMobileMenuClose = () => {
    //setLocationsMobileMenuVisible(false);
    setSearchMobileMenuVisible(false);
    setNavigationDrawerMobileState();
    setSavingDisabled(false);
  };

  const handleLeftDrawerItemClick = () => {
    setNavigationDrawerMobileState();
    setSavingDisabled(false);
  };

  const leftDrawerList = () => (
    <Fragment>
      <div
        className="app-sidebar-header"
        style={{
          width: '220px',
          height: '80px',
          background: 'transparent',
          borderBottom: '5px',
        }}>
        <Box className="header-logo-wrapper" title="RMS">
          <Link to="/incidents" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              style={{ background: 'transparent' }}
              className="header-logo-wrapper-btn">
              <img className="app-sidebar-logo" alt="" src={IconRms} />
            </IconButton>
          </Link>
          <Box
            className={classes.logoText}
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: colors.defaultBlueDarker,
            }}>
            RMS
          </Box>
        </Box>
        <Box className="app-sidebar-header-btn">
          <Tooltip title="Toggle Navigation" placement="right">
            <IconButton
              style={{ color: colors.defaultBlueDarker2 }}
              onClick={() => setNavigationDrawerMobileState()}
              size="medium">
              <MenuOpenRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Navigation" placement="right">
            <IconButton
              style={{ color: colors.defaultBlueDarker2 }}
              onClick={() => setNavigationDrawerMobileState()}
              size="medium">
              <MenuOpenRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
      <Divider />
      <div>
        <Box sx={{ width: 200 }} role="presentation">
          {menuItems.map((btn, idx) => {
            let isDisabled = handlePermissionChecking(btn) || false;

            if (btn.label === 'Search') {
              const searchPaths = [
                ...searchbulletinPath,
                ...searchPartiesPaths,
                ...searchLocationPaths,
                ...searchPropertiesPaths,
              ];
              return (
                <div key={idx}>
                  <Tooltip
                    key={idx}
                    title={btn.tooltip}
                    onClick={handleSearchMobileMenuVisibility}
                    className={classes.drawerItems}
                    style={{ padding: '0px 0px' }}>
                    <NavLink to="#">
                      <Button className={classes.drawerItemsBtn}>
                        <span style={{ paddingRight: '5px' }}>Search</span>
                        <FontAwesomeIcon icon={['fas', 'angle-down']} />
                      </Button>
                    </NavLink>
                  </Tooltip>
                  <Collapse in={searchMobileMenuVisible} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {searchPaths.map((item, idx) => (
                        <NavLink
                          to={handlePermissionChecking(item) ? '#' : item.path}
                          activeClassName={classes.activeNav}
                          className={
                            handlePermissionChecking(item)
                              ? classes.disabledDrawer
                              : classes.drawerItems
                          }
                          key={idx}>
                          <ListItem
                            disabled={handlePermissionChecking(item)}
                            onClick={handleSearchMobileMenuClose}
                            button
                            className="pl-5">
                            <MenuBulletIcon className={classes.propertyItemIcon} />
                            <Typography className={classes.propertyItemLabel}>
                              {item.label}
                            </Typography>
                          </ListItem>
                        </NavLink>
                      ))}
                    </List>
                  </Collapse>
                </div>
              );
            } else {
              return (
                <Tooltip
                  title={btn.tooltip}
                  key={idx}
                  className={isDisabled ? classes.disabledDrawer : classes.drawerItems}
                  style={{ padding: '0px 0px' }}>
                  <NavLink
                    to={isDisabled ? '#' : btn.link}
                    className={isDisabled ? classes.disabledLink : ''}>
                    <Button
                      onClick={handleLeftDrawerItemClick}
                      className={isDisabled ? classes.disabledDrawerItems : classes.drawerItemsBtn}>
                      {btn.label}
                    </Button>
                  </NavLink>
                </Tooltip>
              );
            }
          })}
        </Box>
      </div>
    </Fragment>
  );

  const renderMobileMenu = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <div style={{ alignSelf: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={() => setNavigationDrawerMobileState()}>
            <MenuIcon style={{ color: 'white' }} />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="left"
            open={navigationDrawerMobileState}
            onClose={() => setNavigationDrawerMobileState()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {leftDrawerList()}
          </Drawer>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ alignSelf: 'center' }}>
            <Button
              color="inherit"
              onClick={handleUserMenuClick}
              className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
              <Box>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot">
                  <Avatar sizes="44" alt={userName} />
                </StyledBadge>
              </Box>
              <span className="pl-2 pl-xl-3 d-flex">
                <span style={{ paddingRight: '8px', textDecoration: 'none' }}>{userName}</span>
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
              {/* <div className="d-none d-xl-block">
                <div className="font-weight-bold pt-2 line-height-1">{userName}</div>
              </div>
              <span className="pl-1 pl-xl-3">
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span> */}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleMenuClose = () => {
    setPropertyMenuVisible(false);
    setSavingDisabled(false);
  };

  const handleLocationsMenuClose = () => {
    setLocationsMenuVisible(false);
    setSavingDisabled(false);
  };

  const handleSearchMenuVisibility = (isDisabled) => {
    !isDisabled && setPropertyMenuVisible(!propertyMenuVisible);
    setLocationsMenuVisible(false);
    setOpenPeopleSearchMenuDesktop(false);
    setSearchMenuVisible(false);
  };

  const handleLocationsMenuVisibility = (isDisabled) => {
    !isDisabled && setLocationsMenuVisible(!locationsMenuVisible);
    setPropertyMenuVisible(false);
    setOpenPeopleSearchMenuDesktop(false);
    setSearchMenuVisible(false);
  };

  const handleSavingDisabled = () => {
    setSavingDisabled(false);
  };
  const handlePeopleSearchMenuVisibilityForDesktop = () => {
    setOpenPeopleSearchMenuDesktop(!openPeopleSearchMenuDesktop);
    if (!openPeopleSearchMenuDesktop) {
      setPropertyMenuVisible(false);
      setLocationsMenuVisible(false);
      setSearchMenuVisible(false);
    }
  };

  const handleSearchMenuClick = (isDisabled) => {
    !isDisabled && setSearchMenuVisible(!searchMenuVisible);
    setLocationsMenuVisible(false);
    setPropertyMenuVisible(false);
    setOpenPeopleSearchMenuDesktop(false);
  };

  const renderSearchMenu = () => {
    return (
      <div className={classes.searchMenu}>
        <div>
          <h5 className="pl-4">Communication</h5>
          <MenuList>
            <ListItemButton
              key={'bulletin'}
              to={'/search/bulletin'}
              disabled={handlePermissionChecking(searchbulletinPath[0])}
              component={NavBase}
              className={classes.subMenuListItemActive}>
              <MenuItem onClick={handleMenuClose} button className={classes.searchMenuItem}>
                <MenuBulletIcon /> {'Bulletin'}
              </MenuItem>
            </ListItemButton>
          </MenuList>
        </div>

        <div>
          <h5 className="pl-4">People</h5>
          <MenuList>
            {searchPartiesPaths.map((item, idx) => {
              const isDisabled = handlePermissionChecking(item);
              return (
                <ListItemButton
                  key={idx}
                  to={item.path}
                  component={NavBase}
                  disabled={isDisabled}
                  className={classes.subMenuListItemActive}>
                  <MenuItem onClick={handleMenuClose} button className={classes.searchMenuItem}>
                    <MenuBulletIcon /> {item.label}
                  </MenuItem>
                </ListItemButton>
              );
            })}
          </MenuList>
        </div>
        <div>
          <h5 className="pl-4">Locations</h5>
          <MenuList>
            {searchLocationPaths.map((item, idx) => {
              const isDisabled = handlePermissionChecking(item);
              return (
                <ListItemButton
                  key={idx}
                  to={item.path}
                  disabled={isDisabled}
                  component={NavBase}
                  className={classes.subMenuListItemActive}>
                  <MenuItem onClick={handleMenuClose} button className={classes.searchMenuItem}>
                    <MenuBulletIcon /> {item.label}
                  </MenuItem>
                </ListItemButton>
              );
            })}
          </MenuList>
        </div>
        <div style={{ textAlign: 'left' }}>
          <h5 className="pl-4">Property</h5>
          <MenuList>
            {searchPropertiesPaths.map((item, idx) => {
              const isDisabled = handlePermissionChecking(item);
              return (
                <ListItemButton
                  key={idx}
                  to={item.path}
                  disabled={isDisabled}
                  component={NavBase}
                  className={classes.subMenuListItemActive}>
                  <MenuItem onClick={handleMenuClose} button className={classes.searchMenuItem}>
                    <MenuBulletIcon /> {item.label}
                  </MenuItem>
                </ListItemButton>
              );
            })}
          </MenuList>
        </div>
      </div>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.leftMenu}>
          {menuItems.map((btn, idx) => {
            const isDisabled = handlePermissionChecking(btn);

            if (btn.label === 'Search') {
              return (
                <Tooltip
                  key={idx}
                  title={btn.tooltip}
                  onClick={() => handleSearchMenuClick(btn.disabled)}
                  className={classes.items}
                  style={{ padding: '10px 10px' }}>
                  <ListItemButton
                    sx={{ flexGrow: '0 !important' }}
                    disabled={isDisabled}
                    to={'#'}
                    component={NavBase}
                    style={isSearchPath()}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        <FontAwesomeIcon icon={btn.icon} className="font-size-xl" />
                        {!btn.disabled && (
                          <FontAwesomeIcon
                            icon={['fas', 'angle-down']}
                            style={{ marginLeft: '3px' }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {btn.label}
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip
                  title={btn.tooltip}
                  key={idx}
                  className={classes.items}
                  style={{ padding: '10px 10px' }}>
                  <ListItemButton
                    disabled={btn.link === '/interviews' || isDisabled}
                    sx={{ flexGrow: '0 !important' }}
                    to={btn.link}
                    component={NavBase}
                    className={classes.listItemActive}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        <FontAwesomeIcon icon={btn.icon} className="font-size-xl" />
                      </Grid>
                      <Grid item xs={12}>
                        {btn.label}
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </Tooltip>
              );
            }
          })}
        </div>
        <div className={classes.rightMenu}>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '150px', color: 'white', marginRight: 10 }}>
            <InputLabel className={classes.outlined} id={`users-clearance-level`}>
              Clearance Level
            </InputLabel>
            <Select
              label="Clearance Level"
              labelId="clearance-level"
              id="clearance-level"
              disabled={userDefaultAgency !== userCurrentAgency}
              classes={{ outlined: classes.outlined }}
              value={userCurrentClearanceLevel}
              renderValue={renderClearanceLevel}
              onChange={clearanceLevelChange}>
              {userClearanceOptions?.map((clearanceLevel, index) => (
                <MenuItem key={index} value={clearanceLevel}>
                  {clearanceLevel.Level} - {clearanceLevel.Description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.rightMenu}>
          <FormControl variant="outlined" size="small" style={{ width: '150px', color: 'white' }}>
            <InputLabel className={classes.outlined} id={`users-agncy`}>
              Agency
            </InputLabel>
            <Select
              label="Party"
              labelId="party-type"
              id="party-type"
              className={classes.outlined}
              value={userCurrentAgency}
              disabled={disableAgencyChange}
              onChange={handleAgencyChange}>
              {agenciesList.map((agency, index) => (
                <MenuItem key={index} value={agency.AgencyID}>
                  {agency.AgencyID}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.rightMenu} style={{ marginRight: '-15px' }}>
          <Tooltip
            title="Share"
            onClick={handleShareMenuToggle}
            ref={shareMenuAnchorRef}
            aria-haspopup="true">
            <Button
              disabled={!sharesPermission}
              classes={{ root: classes.itemsBtn, label: classes.label }}>
              <Badge badgeContent={totalShares} color="error">
                <FontAwesomeIcon icon="share-alt" className="font-size-xl" />
              </Badge>
              Share
            </Button>
          </Tooltip>
          <Popper
            open={shareMenuIsOpen}
            anchorEl={shareMenuAnchorRef.current}
            role={undefined}
            transition
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleShareMenuClose}>
                    <MenuList autoFocusItem={shareMenuIsOpen} id="menu-list-grow">
                      <RMSTopbarShareDialog sharesData={sharesData} readShare={readShare} />
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <div className={classes.rightMenu} style={{ marginRight: '-15px' }}>
          <Tooltip title="Bulletins">
            <UnreadBulletins />
          </Tooltip>
        </div>
        <div className={classes.rightMenu}>
          <Button
            onClick={handleUserMenuClick}
            className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
            <Box className={classes.avatar} style={{ display: 'flex' }}>
              <div>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot">
                  <Avatar sizes="44" alt={userName} />
                </StyledBadge>
              </div>
            </Box>
            {windowWidth <= 1335 && (
              <span className="pl-2 pl-xl-3 d-flex">
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            )}
            {windowWidth > 1335 && (
              <span className="pl-2 pl-xl-3 d-flex">
                <span
                  style={{ paddingRight: '8px', textDecoration: 'none' }}
                  className={classes.outlined}>
                  {userName}
                </span>
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            )}
          </Button>
        </div>
      </div>
    );
  };

  const handleOpenSettings = () => {
    authorizeExternalApp();
    //onClose();
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdDown>{renderDesktopMenu()}</Hidden>
          <Hidden lgUp>{renderMobileMenu()}</Hidden>
          {renderUserMenu()}
        </Toolbar>
      </AppBar>
      {/* {searchMenuVisible && (
        <div className={classes.fwMenuWrap} onClick={() => setSearchMenuVisible(false)}>
          <Card className={classes.fullWidthMenu}>{renderSearchMenu()}</Card>
      {propertyMenuVisible && (
        <div className={classes.fwMenuWrap} onClick={() => setPropertyMenuVisible(false)}>
          <Card className={classes.fullWidthMenu}>{renderPropertiesMenu()}</Card>
        </div>
      )} */}
      <div style={{ position: 'relative' }}>
        {/* {openPeopleSearchMenuDesktop && (
          <div
            className={classes.peopleMenuWrap}
            onClick={() => setOpenPeopleSearchMenuDesktop(false)}>
            <Card>{renderPeopleMenu()}</Card>
          </div>
        )}
        {locationsMenuVisible && (
          <div className={classes.locationsMenuWrap} onClick={() => setLocationsMenuVisible(false)}>
            <Card>{renderLocationsMenu()}</Card>
          </div>
        )} */}
        {searchMenuVisible && (
          <div className={classes.fwMenuWrap} onClick={() => setSearchMenuVisible(false)}>
            <Card className={classes.fullWidthMenu}>{renderSearchMenu()}</Card>
          </div>
        )}
      </div>

      {displayAbout && <AboutDialog close={closeAbout} />}
      <RMSConfirmationModal
        title={
          isFormDirty
            ? 'You have unsaved changes in this form!'
            : 'Changing agency will close this incident'
        }
        text={isFormDirty ? 'Do you want to save changes?' : 'Do you wish to proceed?'}
        saveBtnName="Yes"
        noBtnName="Cancel"
        btnName={isFormDirty ? 'No' : null}
        open={openConfirmation}
        closeDialog={() => setOpenConfirmation(false)}
        handleModalSave={() => handleDirtyFormSaving()}
        handleSubmit={() => handleSubmitConfirmation()}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  user: state.user,
  network: state.offline,
  themeMode: state.theme.mode,
  navigationDrawerMobileState: state.ui.navigationDrawerMobileState,
  wsClient: state.websocket.websocket,
  agencies: state.codeAgencies?.agencies,
  appInfo: state.appInfo,
  incidentFilter: state.uiHomeScreen.incidentFilter,
  isFormDirty: state.incident.isFormDirty,
});

export default connect(mapStateToProps, {
  setMode,
  setRecordActive,
  setUserAuthenticated,
  setUserAgency,
  setUserClearanceLevel,
  setNavigationDrawerMobileState,
  resetRecordAdvanceSearchKeyword,
  setSavingDisabled,
  setUserClearanceOptions,
})(TopBar);
